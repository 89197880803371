/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.background{
  background: url('/assets/img/RedBackground.png') no-repeat 50% 100%;
}
ion-content {
  --background:none;
  background: url('/assets/img/RedBackground.png')no-repeat 50% 100%;
  background-size: cover;
  border: 15px solid black;

}
ion-item{
  --background: white;
  --color: black;
  color: black;
}

.license-gen-popover .popover-content {
  --width: 1490px;
  // max-width: 490px;
  --height: 650px;
  // --overflow: hidden;
  // overflow: hidden;
  --background: #3c3cc9;
}

.fullWidth{
  width: 100%;
}
.lcs-gen-popover {
  --width: 50rem;
  --height: 50 rem;
  --background: #3c3cc9;
}
